import { Injectable } from '@angular/core';
import SafeUrlAssembler from 'safe-url-assembler';

import { HttpClientService } from '../../core/services/http/http-client.service';
import { ApiEndpointService } from '../../core/services/api-endpoint.service';
import { SignUp } from '../model/sign-up.model';

@Injectable()
export class SignUpService {

    constructor(private httpClientService: HttpClientService, private apiEndpointService: ApiEndpointService) {

    }

    /**
     * Register new user.
     *          
     * @param {string} specialInstruction
     * @returns {Observable<Response>}
     */
    public registerUser(data: SignUp) {

        const endpoint = SafeUrlAssembler()
            .template(this.apiEndpointService.getEndpoint('signupUser'))
            .toString();
        return this.httpClientService.post<any>(endpoint, data);
    }
}
